import { Loading } from '@shopify/app-bridge-react'
import { SkeletonPage, LegacyCard, SkeletonBodyText, SkeletonDisplayText } from '@shopify/polaris'
import ReviewBanner from './ReviewBanner'
import Footer from './Footer'

export default function Skeleton() {
  return (
    <>
      <Loading />
      <ReviewBanner />
      <SkeletonPage primaryAction>
        <LegacyCard sectioned>
          <SkeletonDisplayText size="small" />
          <br />
          <SkeletonBodyText />
          <br /> <br />
          <SkeletonBodyText />
        </LegacyCard>
      </SkeletonPage>
      <Footer />
    </>
  )
}
