import { Banner, Link } from '@shopify/polaris'
import { useState } from 'react'

export default function ReviewBanner() {
  const [display, setDisplay] = useState(true)
  return <>
    {display && (
      <div
        style={{ maxWidth: '750px', marginLeft: 'auto', marginRight: 'auto', marginTop: '1%' }}
      >
        <Banner
          tone="success"
          onDismiss={() => {
            setDisplay(false)
          }}
        >
          <p>
            Your transparent feedbacks encourage us and make improvements to the app.{' '}
            <Link url="https://apps.shopify.com/bulk-fulfill/reviews" external>
              ⭐ Leave a review ⭐
            </Link>
          </p>
        </Banner>
      </div>
    )}
  </>;
}
