import { FooterHelp, Link, Banner, Icon, BlockStack, InlineStack, Box } from '@shopify/polaris'
import { QuestionCircleIcon } from '@shopify/polaris-icons'

export default function Footer() {
  return (
    <FooterHelp>
      <InlineStack gap="100" align="center">
        <Icon source={QuestionCircleIcon} />
        Got any trouble or have recommendations with this new app? Mail us at{' '}
        <Link url="mailto:steve@upatra.com" external>
          steve@upatra.com
        </Link>
      </InlineStack>
    </FooterHelp>
  )
}
