import { useEffect } from 'react'
import mixpanel from 'mixpanel-browser'

import { Text, Page, Layout, Card, InlineGrid, InlineStack, BlockStack, Box, Icon } from '@shopify/polaris'
import { useRouter } from 'next/router'

import { useAuth } from '@/context'
import ReviewBanner from '@/components/ReviewBanner'
import Footer from '@/components/Footer'
import Skeleton from '@/components/Skeleton'
import { PackageFulfilledIcon, OrderFulfilledIcon, PackageReturnedIcon, ShieldCheckMarkIcon, PaperCheckIcon, DomainRedirectIcon } from '@shopify/polaris-icons'

export async function getServerSideProps(context: any) {
  const shop = context.query.shop
  if (!shop) {
    return {
      props: {},
    }
  }

  context.res.setHeader(
    'Content-Security-Policy',
    `frame-ancestors https://${shop} https://admin.shopify.com;`
  )

  return {
    props: {},
  }
}

export default function ResourceListExample() {
  const { isLoading } = useAuth()
  const router = useRouter()

  useEffect(() => {
    mixpanel.track_pageview({ page: 'Home' })
  }, [])

  const fulfillmentOptions = [
    {
      fulfillmentOption: 'Mark as Fulfill',
      description: 'Bulk mark as fulfill without tracking number',
      url: `/fulfillment/mark-fulfillment${window.location.search}`,
      icon: OrderFulfilledIcon,
    },
    {
      fulfillmentOption: 'Create Fulfillment',
      description: 'Bulk create fulfillment with tracking number & Sync Tracking to Paypal',
      url: `/fulfillment/create-fulfillment${window.location.search}`,
      icon: PackageFulfilledIcon,
    },
    {
      fulfillmentOption: 'Replace Tracking Number',
      description:
        'Bulk replace tracking number, tracking url, tracking company & Sync Tracking to Paypal',
      url: `/fulfillment/update-fulfillment${window.location.search}`,
      icon: PackageReturnedIcon,
    },
    {
      fulfillmentOption: 'Verify Paypal Tracking',
      description: 'Bulk check if tracking is synced to Paypal (only check without push tracking)',
      url: `/fulfillment/paypal-tracking-verification${window.location.search}`,
      icon: ShieldCheckMarkIcon,
      badge: 'Free'
    },
    {
      fulfillmentOption: 'Sync to Paypal from Order',
      description: 'Bulk sync tracking to Paypal from Order',
      url: `/fulfillment/paypal-tracking-order${window.location.search}`,
      icon: PaperCheckIcon,
    },
    {
      fulfillmentOption: 'Sync directly to Paypal',
      description: 'Bulk directly sync tracking to Paypal',
      url: `/fulfillment/direct-paypal-tracking${window.location.search}`,
      icon: DomainRedirectIcon,
    },
  ]

  return (
    <>
      {isLoading && <Skeleton />}

      {!isLoading && (
        <>
          <ReviewBanner />

          <Page title="Choose your option" subtitle="Pick your most suitable option">
            <Layout>
              <Layout.Section>
                <InlineGrid gap="500" columns="3">
                  {fulfillmentOptions.map((item) => (
                    <a href={item.url} className="fulfillment-option" key={item.fulfillmentOption}>
                      <Card>
                        <InlineStack wrap={false} blockAlign="center" gap="400" align="center">
                          <Box padding="500" background="bg-fill-tertiary" color="text" borderRadius="200" as="legend">
                            <Icon source={item.icon} />
                          </Box>
                          <Box minHeight="70px">
                            <BlockStack gap="150">
                              <Box>
                                <Text variant="headingMd" as="h6">
                                  {item.fulfillmentOption}
                                </Text>
                              </Box>
                              <Box>
                                <Text variant="bodySm" as="p" tone="subdued">
                                  {item.description}
                                </Text>
                              </Box>
                            </BlockStack>
                          </Box>
                        </InlineStack>
                      </Card>
                    </a>
                  ))}
                </InlineGrid>
              </Layout.Section>
            </Layout>
          </Page>

          <Footer />
        </>
      )}
    </>
  )
}
